import {
	ChangeDetectorRef,
	Directive,
	EmbeddedViewRef,
	Input,
	OnDestroy,
	TemplateRef,
	ViewContainerRef
} from '@angular/core';
import { of, Subject, takeUntil, tap } from 'rxjs';

import { FeatureService, FeatureKeys } from '../../data';

/**
 * Based upon `*ngIf`. See https://github.com/angular/angular/blob/master/packages/common/src/directives/ng_if.ts
 */
@Directive({
	selector: '[hasFeature]',
	standalone: true
})
export class HasFeatureDirective implements OnDestroy {
	private destroyed$: Subject<void>;
	private thenTemplateRef: TemplateRef<any> | null = null;
	private thenViewRef: EmbeddedViewRef<any> | null = null;
	private elseTemplateRef: TemplateRef<any> | null = null;
	private elseViewRef: EmbeddedViewRef<any> | null = null;
	private feature: FeatureKeys | FeatureKeys[] = [];

	@Input() public set hasFeature(feature: FeatureKeys | FeatureKeys[]) {
		this.feature = feature;
		this.updateView();
	}

	@Input() public set hasFeatureElse(ngTemplate: TemplateRef<any>) {
		this.elseTemplateRef = ngTemplate;
		this.elseViewRef = null;
		this.updateView();
	}

	constructor(
		templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private readonly featureService: FeatureService,
		private readonly cdRef: ChangeDetectorRef
	) {
		this.thenTemplateRef = templateRef;
	}

	public ngOnDestroy(): void {
		this.dispose();
	}

	private updateView(): void {
		this.dispose();

		this.destroyed$ = new Subject();

		(this.feature ? this.featureService.hasFeature(this.feature) : of(true))
			.pipe(
				tap((hasFeature) => {
					this.viewContainer.clear();

					if (hasFeature) {
						this.viewContainer.clear();
						this.elseViewRef = null;

						if (this.thenTemplateRef) {
							this.thenViewRef = this.viewContainer.createEmbeddedView(this.thenTemplateRef);
						}
					} else {
						if (!this.elseViewRef) {
							this.viewContainer.clear();
							this.thenViewRef = null;

							if (this.elseTemplateRef) {
								this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
							}
						}
					}

					// Iben: Detect the changes so that the view gets updated
					this.cdRef.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private dispose(): void {
		if (this.destroyed$) {
			this.destroyed$.next();
			this.destroyed$.complete();
		}
	}
}
